import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { DeviceService } from '../services';

export const httpDeviceInterceptor: HttpInterceptorFn = (req, next) => {
  const device = inject(DeviceService);

  req = req.clone({
    setHeaders: {
      'X-Device-ID': device.uid(),
      'X-Device-Name': navigator.userAgent,
    },
  });

  return next(req);
};
