import { Injectable, computed } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  uid = computed(() => {
    let uid = localStorage.getItem('uid');

    if (!uid) {
      uid = Math.random().toString(36).substring(2);
      localStorage.setItem('uid', uid);
    }

    return uid;
  });
}
